import 'core-js/stable'
import 'regenerator-runtime/runtime'
import axios from 'axios'

import 'components/App'

// Log out the current user if their session is timed out
//  Runs every 60 seconds
const node = document.getElementById('page-data') as HTMLInputElement
const data = JSON.parse(node.getAttribute('data') || '{}')
if (data.auth && data.auth.signedIn) {
  window.setInterval(() => {
    axios.get('/check_session').then((response: any) => {
      if (response.data.timed_out) {
        window.location.replace('/timeout')
      }
    })
  }, 60000)
}

// Prevent click-jacking
if (window.top !== window.self) window.top!.location.replace(window.self.location.href)
