import React, { Component } from 'react'
import { Row, Col, Card } from 'react-materialize'

class FlashMessages extends Component {
  state = {
    messages: [],
  }

  componentWillMount() {
    const node = document.querySelector('#page-data') as HTMLInputElement
    this.setState({
      messages: node ? JSON.parse(node.getAttribute('flash-messages') || '[]') : [],
    })
  }

  render() {
    return (
      <div>
        {this.state.messages.map((message: string[]) => {
          return (
            <Row key={message}>
              <Col s={12} l={6} push="l3">
                <Card className={`${message[0] === 'alert' ? 'red' : 'green'}`}>
                  <span className="white-text">{message[1]}</span>
                </Card>
              </Col>
            </Row>
          )
        })}
      </div>
    )
  }
}

export default FlashMessages
