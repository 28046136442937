import React, { Component } from 'react'
import { Navbar, NavItem } from 'react-materialize'
import { LogOut } from 'react-feather'
import axios from 'axios'
import { ReactComponent as Logo } from '../../images/dch-supporter-logo.svg'
import { getCSRFToken } from '../../helpers'

export default class Header extends Component<headerInterface> {
  constructor(props: headerInterface) {
    super(props)

    this.logOut = this.logOut.bind(this)
  }

  logOut(): void {
    axios
      .delete('/sign-out', {
        data: {
          authenticity_token: getCSRFToken(),
        },
      })
      .then((response) => {
        window.location.replace(response.data.url)
      })
      .catch(() => {
        window.location.reload()
      })
  }

  render() {
    const { auth, info } = this.props

    const activeItem = (item: string) => {
      const section = window.location.pathname.split('/')[1]

      return item === section ? 'active' : ''
    }

    return (
      <header className="container">
        {auth.signedIn ? (
          <Navbar brand={<Logo />} alignLinks="right" className="transparent z-depth-0">
            <NavItem className={activeItem('dashboard')} href="/dashboard">
              My Dashboard
            </NavItem>
            <NavItem className={activeItem('profile')} href="/profile">
              My Profile
            </NavItem>
            <NavItem href={info.supporterHelp} rel="noopener noreferrer" target="_blank">
              Help
            </NavItem>
            <NavItem href="#" onClick={this.logOut} className="valign-wrapper">
              <LogOut size={20} height={36} />
            </NavItem>
          </Navbar>
        ) : (
          <nav className="transparent z-depth-0">
            <div className="nav-wrapper">
              <Logo className="brand-logo center fill-white" />
            </div>
          </nav>
        )}
      </header>
    )
  }
}
