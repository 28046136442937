// Needed for IE support
import 'core-js/features/array'
import 'core-js/features/number'
import 'core-js/features/promise'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Header from './layout/Header'
import Footer from './layout/Footer'
import FlashMessages from './layout/FlashMessages'
import 'materialize-css'
import './App.css'

const node = document.getElementById('page-data') as HTMLInputElement
const data = JSON.parse(node.getAttribute('data') || '{}')

class App extends Component<any, any> {
  render() {
    return (
      <div className="wrapper">
        <div className="background-wrapper">
          <Header auth={data.auth} info={data.info} />
          <FlashMessages />
          <div id="content" className="container" />
        </div>
        <Footer {...data.info} />
      </div>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('app'))
