import { differenceInMinutes, parseISO, isValid, format, parse } from 'date-fns'

export function getCSRFToken(): string {
  const meta = document.head.querySelector('meta[name="csrf-token"][content]') as HTMLMetaElement
  return meta ? meta.content || '' : ''
}

export function getURLParameter(param: string): string {
  const urlParams = new URLSearchParams(location.search)
  return urlParams.get(param) || ''
}

export function slashedDateToISODate(date: string): string {
  return format(parse(date, 'M/dd/yyyy', new Date()), 'yyyy-M-dd')
}

export function dateToReadable(date: string): string {
  const parsedDate = parseISO(date)
  const date_minutes_diff = differenceInMinutes(new Date(), parsedDate)
  let readable: string

  if (!isValid(parsedDate)) {
    readable = '---'
  } else if (date_minutes_diff <= 5) {
    readable = 'Just now'
  } else if (date_minutes_diff <= 60) {
    readable = `${date_minutes_diff.toFixed()} minutes`
  } else if (date_minutes_diff <= 540) {
    readable = `${(date_minutes_diff / 60).toFixed()} hours`
  } else if (date_minutes_diff <= 1440) {
    readable = 'Today'
  } else if (date_minutes_diff <= 2880) {
    readable = 'Yesterday'
  } else if (date_minutes_diff <= 20160) {
    readable = `${(date_minutes_diff / 1440).toFixed()} days`
  } else {
    readable = format(parsedDate, 'M/d/yyyy')
  }

  return readable
}

// From: https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#gistcomment-3238804
const getRange = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(null)
    .map((_, i) => i + start)
}

export function paginationPages(currentPage: number, pageCount: number) {
  const delta = 10

  const range = {
    start: Math.round(currentPage - delta / 2),
    end: Math.round(currentPage + delta / 2),
  }

  if (range.start - 1 === 1 || range.end + 1 === pageCount) {
    range.start += 1
    range.end += 1
  }

  let pages: any =
    currentPage > delta
      ? getRange(Math.min(range.start, pageCount - delta), Math.min(range.end, pageCount))
      : getRange(1, Math.min(pageCount, delta + 1))

  const withDots = (value: any, pair: any) => (pages.length + 1 !== pageCount ? pair : [value])

  if (pages[0] !== 1) {
    pages = withDots(1, [1, '...']).concat(pages)
  }

  if (pages[pages.length - 1] < pageCount) {
    pages = pages.concat(withDots(pageCount, ['...', pageCount]))
  }

  return pages
}
