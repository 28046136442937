import React, { Component } from 'react'
import { Modal } from 'react-materialize'
import { format } from 'date-fns'

export default class Footer extends Component<infoInterface> {
  render() {
    const { dynamiCareSite, termsOfUse, privacyPolicy } = this.props
    return (
      <footer className="page-footer">
        <div className="container">
          <div className="row">
            <div className="col s12">
              <div className="center-align">
                <small>
                  <p>&copy; Copyright {format(new Date(), 'yyyy')} DynamiCare Health</p>
                  <Modal
                    trigger={
                      <span className="cursor-pointer">
                        The saliva and breath testing devices used with the DynamiCare Health platform provide only
                        qualitative, preliminary results.
                        <span className="coral-link">&nbsp;Click here&nbsp;</span>for full Disclaimer.
                      </span>
                    }
                    className="padding-3"
                  >
                    <p>
                      The DynamiCare Health platform, including drug testing, GPS tracking, surveys, support tools, and
                      incentives, is designed to encourage choices which, as part of a healthy lifestyle, may help those
                      recovering from substance addictions. Drug tests included in the DynamiCare Health platform are
                      intended to be used to discourage substance use and to help inform healthcare providers.
                    </p>
                    <p>
                      The use of the DynamiCare Health platform should be strictly limited to the foregoing. It should
                      not be used for any other purpose, including, without limitation, for confirming the presence or
                      the absence of any substance (drug of abuse) in a user’s specimen, compliance with any substance
                      abuse or other program, or any medical or other diagnostic procedure. The saliva and breath
                      testing devices used with the DynamiCare Health platform provide only qualitative, preliminary
                      results. (See the instructions included in the test device packaging for more information.)
                    </p>
                  </Modal>
                </small>
              </div>
              <div className="center-align">
                <small>
                  <a href={termsOfUse} rel="noopener noreferrer" target="_blank" className="blue-link">
                    Terms of Use
                  </a>
                  &nbsp;|&nbsp;
                  <a href={privacyPolicy} rel="noopener noreferrer" target="_blank" className="blue-link">
                    Privacy Policy
                  </a>
                  &nbsp;|&nbsp;
                  <a href={dynamiCareSite} rel="noopener noreferrer" target="_blank" className="blue-link">
                    DynamiCare Site
                  </a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
